@charset "UTF-8";

// ==================================================
//  @use
//  mixin: @include index.sp {~}
//  color: color.$write-main
// ==================================================
@use "mixin" as mixin;
@use "color" as color;

// ==================================================
//  Youtube
//    #youtubeのラッパー要素について
//    - min-height の設定・調整を必ず行うこと
//    - position: relative, overflow: hidden, z-index: 1 は必ず設定すること
// ==================================================
#youtube {
  display: block;
  position: absolute;
  z-index: 1;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 177.77777778vh; // 16:9 の幅→16 ÷ 9＝ 177.77%
  height: 56.25vw; // 16:9の幅 → 9 ÷ 16 = 56.25%
  min-height: 100%;
  min-width: 100%;
}
// Javascript
.youtube-mask {
  content: "";
  display: block;
  width: 100%;
  height: 100%;
  background-color: transparent;
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
}
